export const convertToTargetTimezone = (time, targetOffset) => {
    // Assume time is for today's date
    const today = new Date();
    const [hours, minutes] = time.split(':');

    // Create a Date object with the specified time
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes);

    // Get the current timezone offset in minutes, convert to hours
    const currentOffset = date.getTimezoneOffset() / 60;

    // Calculate the time difference
    const difference = targetOffset - currentOffset;

    // Adjust the time
    date.setHours(date.getHours() + difference);

    // Return the new time as a string
    return date.toISOString().substr(11, 5);
}